<template>
    <div class="calls-managers">
        <div class="calls-managers-title">
            <h2>מסך ניהול קריאות</h2>
            <div class="return-btn">
                <i @click="$emit('go_back')" class="material-icons">arrow_forward</i>
            </div>
        </div>
        <div class="calls-managers-options">
            <el-button @click="state='CallScreen'" :type="state=='CallScreen'?'success':'primary'">קריאות</el-button>
            <el-button v-if="role >= 99" @click="state='ServicesPeopleManage'"  style="margin: 0 5px;" :type="state=='ServicesPeopleManage'?'success':'primary'">אנשי שירות</el-button>
        </div>
        <div class="calls-managers-content">
            <KeepAlive>
                <component :is="state" />
            </KeepAlive>
        </div>

    </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue';
import {role} from '../../../../Methods/Utils'
export default {
    emits:['go_back'],
    components:{
        CallScreen:defineAsyncComponent(() => import('./Manage/CallScreen.vue')),
        ServicesPeopleManage:defineAsyncComponent(() => import('./Manage/ServicesPeopleManage.vue'))
    },
    setup () {
        
        const state = ref('CallScreen')

        return {
            state,
            role
        }
    }
}
</script>

<style scoped>
    .calls-managers{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        background: #fff;
        color: #333;
        display: flex;
        flex-direction: column;
        padding: 5px 0;
    }
    .calls-managers-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .calls-managers-options{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .calls-managers-content{
        width: 100%;
        height: calc(100% - 100px);
    }

    .return-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: var(--blue);
        border-radius: 50%;
        cursor: pointer;
    }
</style>